import React, { useState } from 'react';
import styled from 'styled-components';
import { FaRegCopy } from 'react-icons/fa';

const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 0.3rem;
  color: #d1d5db; /* Light gray color */
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Container = styled.div`
  background-color: #111827;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

const Title = styled.h1`
  margin-bottom: 2rem;
`;

const Form = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  margin-bottom: 1rem;
  width: 100%;
`;

const CommandContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin-bottom: 1rem;
  position: relative;
`;

const CommandTitle = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: yellow;
`;

const Command = styled.div`
  background-color: #1F2937;
  padding: 1rem;
  border-radius: 4px;
  white-space: nowrap;
  overflow-x: auto;
  min-height: 4rem;
  position: relative;
  &:hover button, &:focus-within button {
    opacity: 1;
  }
`;

const CopyButton = styled.button`
  background-color: transparent;
  color: #3F83F8;
  border: none;
  border-radius: 2px;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.3rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s;
  &:hover {
    color: white;
    opacity: 1;
  }
`;

const Notification = styled.div`
  position: sticky;
  background-color: #3F83F8;
  color: white;
  border-radius: 2px;
  padding: 0.5rem;
  font-size: 0.875rem;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.5s;
`;

const UsefulCommands = () => {
  const [nodename, setNodename] = useState('');
  const [binary, setBinary] = useState('');
  const [servicename, setServicename] = useState('');
  const [network, setNetwork] = useState(''); // New state for network input
  const [chainid, setChainid] = useState('');
  const [port, setport] = useState('');
  const [copied, setCopied] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const handleNodenameChange = (e) => setNodename(e.target.value);
  const handleBinaryChange = (e) => setBinary(e.target.value);
  const handleServicenameChange = (e) => setServicename(e.target.value);
  const handleNetworkChange = (e) => setNetwork(e.target.value);
  const handlePortChange = (e) => setport(e.target.value);
  const handleChainid = (e) => setChainid(e.target.value);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(text);
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 1100);
    });
  };

  const commands = [
    {
      title: 'Custom Port',
      command: `sed -i -e "s|^node *=.*|node = \\\"tcp://localhost:${port}57\\\"|" $HOME/.${binary}/config/client.toml`,
    },
    {
      title: '',
      command: `sed -i -e "s%:1317%:${port}17%; s%:8080%:${port}80%; s%:9090%:${port}90%; s%:9091%:${port}91%; s%:8545%:${port}45%; s%:8546%:${port}46%; s%:6065%:${port}65%" $HOME/.${binary}/config/app.toml`,
    },
    {
      title: '',
      command: `sed -i -e "s%:26658%:${port}58%; s%:26657%:${port}57%; s%:6060%:${port}60%; s%:26656%:${port}56%; s%:26660%:${port}61%" $HOME/.${binary}/config/config.toml`,
    },
        {
      title: 'Set Seeds mode',
      command: `sed -i 's/seed_mode = false/seed_mode = true/' $HOME/.${binary}/config/config.toml`,
    },
    {
      title: 'Get Seeds Peers',
      command: `echo $(${servicename} tendermint show-node-id)'@'${nodename}-testnet.sychonix.com':'$(cat $HOME/.${binary}/config/config.toml | sed -n '/Address to listen for incoming connection/{n;p;}' | sed 's/.*://; s/".*//')`,
    },
    {
      title: 'Pruning',
      command: `sed -i -e 's|^pruning *=.*|pruning = "custom"|' -e 's|^pruning-keep-recent *=.*|pruning-keep-recent = "2000"|' -e 's|^pruning-keep-every *=.*|pruning-keep-every = "0"|' -e 's|^pruning-interval *=.*|pruning-interval = "11"|' $HOME/.${binary}/config/app.toml`,
    },
    {
      title: 'Prepare cosmovisor directories',
      command: `mkdir -p $HOME/.${binary}/cosmovisor/genesis/bin`,
    },
    {
      title: '',
      command: `ln -s $HOME/.${binary}/cosmovisor/genesis $HOME/.${binary}/cosmovisor/current -f`,
    },
    {
      title: 'Copy binary to cosmovisor directory',
      command: `cp $(which ${servicename}) $HOME/.${binary}/cosmovisor/genesis/bin`,
    },
    {
      title: 'Systemd config',
      command: `sudo tee /etc/systemd/system/${servicename}.service > /dev/null << EOF
[Unit]
Description=${nodename} node service
After=network-online.target
[Service]
User=$USER
WorkingDirectory=$HOME/.${binary}
ExecStart=$(which cosmovisor) run start
Restart=on-failure
RestartSec=5
LimitNOFILE=65535
Environment="DAEMON_HOME=$HOME/.${binary}"
Environment="DAEMON_NAME=${servicename}"
Environment="UNSAFE_SKIP_BACKUP=true"
Environment="DAEMON_ALLOW_DOWNLOAD_BINARIES=true"
[Install]
WantedBy=multi-user.target
EOF`,
    },
    {
      title: 'Peers Setting',
      command: `sed -i 's/max_num_inbound_peers =.*/max_num_inbound_peers = 50/g' $HOME/.${binary}/config/config.toml`,
    },
    {
      title: '',
      command: `sed -i 's/max_num_outbound_peers =.*/max_num_outbound_peers = 50/g' $HOME/.${binary}/config/config.toml`,
    },
    {
      title: 'Stop Service',
      command: `sudo systemctl stop ${servicename}`,
    },
    {
      title: 'Back up priv_validator_state.json',
      command: `cp ~/.${binary}/data/priv_validator_state.json  ~/.${binary}/priv_validator_state.json`,
    },
    {
      title: 'Unsafe reset tendermint',
      command: `${servicename} tendermint unsafe-reset-all --home $HOME/.${binary} --keep-addr-book`,
    },
    {
      title: 'Download snapshot',
      command: `curl https://snapshot.sychonix.com/${network}/${nodename}/${nodename}-snapshot.tar.lz4 | lz4 -dc - | tar -xf - -C $HOME/.${binary}`,
    },
    {
      title: 'Restore priv_validator_state.json',
      command: `mv $HOME/.${binary}/priv_validator_state.json.backup $HOME/.${binary}/data/priv_validator_state.json`,
    },
    {
      title: 'Restart Service',
      command: `sudo systemctl restart ${servicename} && sudo journalctl -u ${servicename} -f -o cat`,
    },
    
  ];

  return (
    <Container>
      <Title>Configuration Management</Title>
      <Form>
        <FormItem>
          <Label>Node Name</Label>
          <Input
            type="text"
            value={nodename}
            onChange={handleNodenameChange}
            placeholder="Enter node name"
          />
        </FormItem>
        <FormItem>
          <Label>Binary</Label>
          <Input
            type="text"
            value={binary}
            onChange={handleBinaryChange}
            placeholder="Binary (e.g., .empe-chain)"
          />
        </FormItem>
        <FormItem>
          <Label>Service Name</Label>
          <Input
            type="text"
            value={servicename}
            onChange={handleServicenameChange}
            placeholder="Binary Name (e.g., .emped)"
          />
        </FormItem>
        <FormItem>
          <Label>Network Type</Label>
          <Input
            type="text"
            value={network}
            onChange={handleNetworkChange}
            placeholder="Enter network type"
          />
        </FormItem>
        <FormItem>
          <Label>Chain ID</Label>
          <Input
            type="text"
            value={chainid}
            onChange={handleChainid}
            placeholder="Enter Chain id"
          />
        </FormItem>
        <FormItem>
          <Label>Port</Label>
          <Input
            type="text"
            value={port}
            onChange={handlePortChange}
            placeholder="Enter Port (e.g., 175)"
          />
        </FormItem>
      </Form>
      {commands.map((cmd, index) => (
        <CommandContainer key={index}>
          <CommandTitle>{cmd.title}</CommandTitle>
          <Command>
            <CopyButton onClick={() => handleCopy(cmd.command)}>
              {copied === cmd.command && <Notification show={showNotification}>Copied!</Notification>}
              <FaRegCopy />
            </CopyButton>
            <pre>{cmd.command}</pre>
          </Command>
        </CommandContainer>
      ))}
    </Container>
  );
};

export default UsefulCommands;
