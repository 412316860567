import React, { useState } from 'react';
import styled from 'styled-components';
import { FaRegCopy } from 'react-icons/fa';

const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 0.3rem;
  color: #d1d5db; /* Light gray color */
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Container = styled.div`
  background-color: #111827;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

const Title = styled.h1`
  margin-bottom: 2rem;
`;

const Form = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  margin-bottom: 1rem;
  width: 100%;
`;

const CommandContainer = styled.div`
  width: 100%; /* Use a percentage width for responsiveness */
  max-width: 600px; /* Adjust as needed */
  margin-bottom: 1rem;
  position: relative;
`;

const CommandTitle = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: yellow;
`;

const Command = styled.div`
  background-color: #1F2937;
  padding: 1rem;
  border-radius: 4px;
  white-space: nowrap;
  overflow-x: auto;
  min-height: 4rem;
  position: relative;
  &:hover button, &:focus-within button {
    opacity: 1;
  }
`;

const CopyButton = styled.button`
  background-color: transparent;
  color: #3F83F8;
  border: none;
  border-radius: 2px;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.3rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s;
  
  &:hover {
    color: white;
    opacity: 1;
  }
`;

const Notification = styled.div`
  position: sticky;
  background-color: #3F83F8;
  color: white;
  border-radius: 2px;
  padding: 0.5rem;
  font-size: 0.875rem;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.5s;
`;

const UsefulCommands = () => {
  const [nodename, setNodename] = useState('');
  const [binary, setBinary] = useState('');
  const [servicename, setServicename] = useState('');
  const [user, setUser] = useState('');
  const [version, setVersion] = useState('');
  const [github, setGithub] = useState('');
  const [planname, setPlanname] = useState('');
  const [copied, setCopied] = useState(null);
  const [block, setBlock] = useState('');
  const [showNotification, setShowNotification] = useState(false);

  const handleNodenameChange = (e) => setNodename(e.target.value);
  const handleBinaryChange = (e) => setBinary(e.target.value);
  const handleServicenameChange = (e) => setServicename(e.target.value);
  const handleVersion = (e) => setVersion(e.target.value);
  const handleGithub = (e) => setGithub(e.target.value);
  const handleplanname = (e) => setPlanname(e.target.value);
  const handleUserChange = (e) => setUser(e.target.value);
  const handleblock = (e) => setBlock(e.target.value);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(text);
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 1100);
    });
  };

  const commands = [
    {
      title: `Build Binaries for ${nodename} node use tar.gz`,
      command: `cd $HOME
wget ${github || '<Git Repository URL tar.gz amd64>'}
tar -xvf ${version || '<Version>'} && rm -rf ${version || '<Version>'}
chmod +x ${servicename || '<Service Name>'}`,
    },
    {
      title: `Build Binaries for ${nodename} node use build`,
      command: `cd $HOME
git clone ${github || '<Git Repository URL>'}
cd ${nodename || '<Node Name>'}
git checkout ${version || '<Version>'}
make build`,
    },
    {
      title: `cosmovisor use make build`,
      command: `make build
mkdir -p $HOME/.${binary || '<Binary Name>'}/cosmovisor/genesis/bin
mv build/initiad $HOME/.${binary || '<Binary Name>'}/cosmovisor/genesis/bin/
rm -rf build
ln -s $HOME/.${binary || '<Binary Name>'}/cosmovisor/genesis $HOME/.${binary || '<Binary Name>'}/cosmovisor/current -f
sudo ln -s $HOME/.${binary || '<Binary Name>'}/cosmovisor/current/bin/${servicename || '<Service Name>'} /usr/local/bin/${servicename || '<Service Name>'} -f`,
    },
    {
      title: `Cosmovisor use make install or change default to cosmovisor`,
      command: `mkdir -p $HOME/.${binary || '<Binary Name>'}/cosmovisor/genesis/bin
cp $(which ${servicename || '<Service Name>'}) $HOME/.${binary || '<Binary Name>'}/cosmovisor/genesis/bin`,
    },
    {
      title: ``,
      command: `sudo ln -s $HOME/.${binary || '<Binary Name>'}/cosmovisor/genesis $HOME/.${binary || '<Binary Name>'}/cosmovisor/current -f
sudo ln -s $HOME/.${binary || '<Binary Name>'}/cosmovisor/current/bin/${servicename || '<Service Name>'} /usr/local/bin/${servicename || '<Service Name>'} -f
`,
    },
    {
        title: 'Systemd config',
command: `sudo tee /etc/systemd/system/${servicename || '<Service Name>'}.service > /dev/null << EOF
[Unit]
Description=${nodename || '<Node Name>'} node service
After=network-online.target
      
[Service]
User=$USER
WorkingDirectory=$HOME/.${binary || '<Binary Name>'}
ExecStart=$(which cosmovisor) run start
Restart=on-failure
RestartSec=5
LimitNOFILE=65535
Environment="DAEMON_HOME=$HOME/.${binary || '<Binary Name>'}"
Environment="DAEMON_NAME=${servicename || '<Service Name>'}"
Environment="UNSAFE_SKIP_BACKUP=true"
Environment="DAEMON_ALLOW_DOWNLOAD_BINARIES=true"
      
[Install]
WantedBy=multi-user.target
EOF`,
      },      
      {
        title: 'Restart Service',
        command: `sudo systemctl daemon-reload
sudo systemctl restart ${servicename} && sudo journalctl -u ${servicename} -f -o cat`,
      },
      {
        title: `Update for Cosmovisor`,
        command: `rm -rf $HOME/.${binary}/cosmovisor/genesis/bin/
mkdir -p $HOME/.${binary}/cosmovisor/genesis/bin
cd $HOME
wget -O ${servicename || '<Binary name>'} ${github || '<Git Repository URL ZIP OR AMD64>'}
chmod +x ${servicename}
mv ${servicename} $HOME/.${binary}/cosmovisor/genesis/bin/
sudo systemctl daemon-reload
sudo systemctl restart ${servicename} && sudo journalctl -u ${servicename} -f -o cat`,
      },
      {
        title: `Update for Cosmovisor specific block height | tar.gz/amd64`,
        command: `rm -rf $HOME/.${binary}/upgrade
mkdir $HOME/.${binary}/upgrade
cd $HOME/.${binary}/upgrade
wget -q ${github || '<Git Repository AMD64>'} -O ${servicename || '<Binary name>'}
sudo chmod +x $HOME/.${binary}/upgrade/${servicename}
cosmovisor add-upgrade ${planname || '<plan name on explorer>'} $HOME/.${binary}/upgrade/${servicename || '<sytemd name>'} --upgrade-height ${block || '<Block Height check on Explorer>'}`,
      },
      {
        title: `Update for Non Cosmovisor amd`,
        command: `cd $HOME
wget -O ${servicename || '<Binary name>'} ${github || '<Git Repository AMD64>'}
chmod +x ${servicename || '<Binary name>'}
sudo mv $HOME/${servicename || '<Binary name>'} $(which ${servicename || '<Binary name>'})
sudo systemctl restart ${servicename}  && sudo journalctl -u ${servicename}  -f`,
      },
      {
        title: `Update for Non Cosmovisor tar.gz`,
        command: `cd $HOME
rm -rf bin
mkdir bin
cd $HOME/bin
curl -LO git clone ${github || '<Git Repository URL tar.gz>'}
tar -xvf ${servicename || '<binary name after install git>'}.tar.gz
chmod +x $HOME/bin/${servicename}
sudo mv $HOME/bin/${servicename} $(which ${servicename})
sudo systemctl restart ${servicename} && sudo journalctl -u ${servicename} -f`,
      },
      {
        title: `Update for Cosmovisor auto`,
        command: `wget ${github || '<Git Repository URL amd54>'} -O ~/.${servicename || '<Binary name>'}/cosmovisor/upgrades/${version || 'e.g v0_6_4'}/bin/${servicename}
sudo systemctl restart ${servicename} && sudo journalctl -u ${servicename} -f`,
      },
  ];

  return (
    <Container>
      <Title>Configuration Management</Title>
      <Form>
        <FormItem>
          <Label>Node Name</Label>
          <Input
            type="text"
            value={nodename}
            onChange={handleNodenameChange}
            placeholder="Enter node name"
          />
        </FormItem>
        <FormItem>
          <Label>Binary Name</Label>
          <Input
            type="text"
            value={binary}
            onChange={handleBinaryChange}
            placeholder="Enter binary name"
          />
        </FormItem>
        <FormItem>
          <Label>Service Name</Label>
          <Input
            type="text"
            value={servicename}
            onChange={handleServicenameChange}
            placeholder="Enter service name"
          />
        </FormItem>
        <FormItem>
          <Label>User Name</Label>
          <Input
            type="text"
            value={user}
            onChange={handleUserChange}
            placeholder="Enter user name"
          />
        </FormItem>
        <FormItem>
          <Label>Version e.g v0_6_4</Label>
          <Input
            type="text"
            value={version}
            onChange={handleVersion}
            placeholder="Enter version"
          />
        </FormItem>
        <FormItem>
          <Label>Git Repository</Label>
          <Input
            type="text"
            value={github}
            onChange={handleGithub}
            placeholder="Enter Git Repository"
          />
        </FormItem>
        <FormItem>
          <Label>Block Height</Label>
          <Input
            type="text"
            value={block}
            onChange={handleblock}
            placeholder="Enter height"
          />
        </FormItem>
        <FormItem>
          <Label>Plan Name on Explorer</Label>
          <Input
            type="text"
            value={planname}
            onChange={handleplanname}
            placeholder="Enter plan name"
          />
        </FormItem>
      </Form>
      {commands.map((cmd, index) => (
        <CommandContainer key={index}>
          <CommandTitle>{cmd.title}</CommandTitle>
          <Command>
            <CopyButton onClick={() => handleCopy(cmd.command)}>
              {copied === cmd.command && <Notification show={showNotification}>Copied!</Notification>}
              <FaRegCopy />
            </CopyButton>
            <pre>{cmd.command}</pre>
          </Command>
        </CommandContainer>
      ))}
    </Container>
  );
};

export default UsefulCommands;
